<template>
  <v-stepper v-model="step" ref="stepperRef" class="formSectionStepperVertical" vertical>
    <template v-for="(section, sectionIndex) in sections">
      <v-stepper-step :key="'step-' + sectionIndex" :complete="step > sectionIndex + 1" :step="sectionIndex + 1">
        <span v-html="section.title"></span>
      </v-stepper-step>
      <v-stepper-content :key="'content-' + sectionIndex" :step="sectionIndex + 1" ref="sectionRef">
        <form-section :inputs="section.inputs" :form-data="formData" @save="$emit('save')" @update="(obj) => $emit('update', obj)"></form-section>
        <v-toolbar flat style="padding-left:0">
          <v-btn v-if="sectionIndex !== 0" :disabled="isUploading" class="no-print" outlined style="margin-right:1em" @click="back">Back</v-btn>
          <v-btn v-if="sectionIndex === sections.length - 1" :disabled="isUploading" color="primary" class="no-print" outlined @click="submit">{{ isUploading ? 'Waiting on files to upload' : submitLabel }}</v-btn>
          <v-btn v-else :disabled="isUploading" class="no-print" outlined @click="next">{{ isUploading ? 'Waiting on files to upload' : 'Next' }}</v-btn>
        </v-toolbar>
      </v-stepper-content>
    </template>
  </v-stepper>
</template>
<style>
div.formSectionStepperVertical div.v-stepper__wrapper {
  padding-left: 10px;
}
div.formSectionStepperVertical div.v-stepper__step--complete i.fas {
  font-size: 1.3em;
}
div.formSectionStepperVertical div.v-stepper__wrapper form.v-form {
  margin-top: 4px;
}
div.formSectionStepperVertical div.v-stepper__wrapper .v-toolbar div.v-toolbar__content {
  padding-left: 0;
}
</style>
<script>
import { ref, computed } from '@vue/composition-api'

export default {
  components: {
    FormSection: () => import('./Section')
  },
  props: {
    sections: {
      type: Array,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    returnButton: String,
    submitLabel: {
      type: String,
      default: 'Submit'
    }
  },
  setup (props, { root, emit }) {
    const isUploading = computed(() => root.$store.state.forms.isUploading)
    const step = ref(1)
    const sectionRef = ref(null)
    const stepperRef = ref(null)

    function submit () {
      if (sectionRef.value[step.value - 1].$children[1].validate()) {
        emit('submit')
      }
    }

    function back () {
      step.value--
      // scroll the page up to the top of the section
      if (window.pageYOffset > stepperRef.value.$el.offsetTop) window.scrollTo(0, stepperRef.value.$el.offsetTop)
    }

    function next () {
      // validate the current page
      if (sectionRef.value[step.value - 1].$children[1].validate()) {
        step.value++
        // scroll the page up to the top of the section
        if (window.pageYOffset > stepperRef.value.$el.offsetTop) window.scrollTo(0, stepperRef.value.$el.offsetTop)
      } else {
        // Find the first element of the page with an error and scroll to it
        root.$nextTick(() => { window.scrollTo(0, document.getElementsByClassName('error--text')[0].offsetTop + stepperRef.value.$el.offsetTop - 20) })
        // window.scrollTo(0, document.getElementsByClassName('error--text')[0].offsetTop)
      }
    }

    return {
      isUploading,
      step,
      sectionRef,
      stepperRef,
      submit,
      back,
      next
    }
  }
}
</script>
